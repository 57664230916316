<template>
    <ul :class="[$style['gallery'], $style[`gallery_${images.length}`]]">
        <li
            v-for="(image, index) in imagesWithSize"
            :key="index"
            :class="$style['gallery__item']"
        >
            <SwImage
                :src="image.src"
                :width="image.width"
                :height="image.height"
                processing="crop"
            />
        </li>
    </ul>
</template>

<script>
const SIZES = {
  XS: { width: 246, height: 246 },
  SM: { width: 336, height: 336 },
  MD: { width: 516, height: 500 },
  LG: { width: 1056, height: 500 }
}

const getImageSizeRelativeToGrid = (imagesLength, index) => {
  switch (imagesLength) {
    case 1:
      return SIZES.LG
    case 2:
      return SIZES.MD
    case 3:
      return SIZES.SM
    case 4:
      return SIZES.XS
    case 5:
      return index < 2 ? SIZES.MD : SIZES.SM
    case 6:
      return SIZES.SM
    case 7:
      return index < 3 ? SIZES.SM : SIZES.XS
    default:
      return SIZES.XS
  }
}

export default {
  name: 'ImageGallery',

  props: {
    images: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    imagesWithSize () {
      return this.images.map((src, index) => ({
        src,
        ...getImageSizeRelativeToGrid(this.images.length, index)
      }))
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";
@import (reference) "./assets/Web.less";

.gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  list-style-type: none;
  gap: @spacing-s;
  margin: auto 0;

  &__item {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    @media screen and (max-width: @bp-mobile-small) {
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      object-fit: cover;
    }

    :global(.sw-image-placeholder) {
      max-height: 100% !important;

      @media screen and (max-width: @bp-mobile-small) {
        max-width: 100% !important;
        aspect-ratio: 1;
      }
    }
  }

  &_2 {
    flex-wrap: nowrap;
    @media screen and (max-width: @bp-mobile-small - 1px) {
      flex-wrap: wrap;
    }

    .gallery__item {
      width: 50%;
      @media screen and (min-width: @bp-mobile-small) and (max-width: @bp-mobile-big) {
        width: 50%;
        height: 349px;
      }
      @media screen and (max-width: @bp-mobile-small) {
        width: 100%;
        height: auto;
      }
    }
  }

  &_3 {
    flex-wrap: nowrap;
    @media screen and (max-width: @bp-mobile-small - 1px) {
      flex-wrap: wrap;
      :global(.sw-image-placeholder) {
        aspect-ratio: 1 !important;
      }
    }
    .gallery__item {
      width: 33.33%;
      height: 370px;
      @media screen and (min-width: @bp-mobile-small) and (max-width: @bp-mobile-big) {
        height: 269px;
      }
      @media screen and (max-width: @bp-mobile-small) {
        width: 100%;
        height: auto;
      }

      :global(.sw-image-placeholder) {
        @media screen and (max-width: @bp-mobile-small) {
          aspect-ratio: auto;
        }
      }
    }
  }

  &_4 {
    @media only screen and (min-width: @bp-mobile-small) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: @spacing-s;
    }

    .gallery__item {
      grid-column: span 3;
      height: 246px;
      padding: 0;
      @media only screen and (min-width: @bp-mobile-small) and (max-width: @bp-mobile-big) {
        grid-column: span 6;
      }
      @media screen and (max-width: @bp-mobile-small - 1px) {
        width: 100%;
        height: auto;
      }
    }
  }

  &_5 {
    @media screen and (min-width: @bp-mobile-small) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: @spacing-s;
    }

    .gallery__item {
      grid-column: span 6;
      height: 246px;
      padding: 0;
      @media screen and (max-width: @bp-mobile-small) {
        width: 100%;
        height: auto;
      }

      &:nth-child(n + 3) {
        grid-column: span 4;
      }
    }
  }

  &_6 {
    @media screen and (min-width: @bp-mobile-small) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: @spacing-s;
    }

    .gallery__item {
      grid-column: span 4;
      height: 246px;
      padding: 0;
      @media screen and (max-width: @bp-mobile-small) {
        width: 100%;
        height: auto;
      }
    }
  }

  &_7 {
    @media screen and (min-width: @bp-mobile-small) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: @spacing-s;
    }

    .gallery__item {
      grid-column: span 4;
      height: 246px;
      padding: 0;
      @media screen and (max-width: @bp-mobile-small) {
        width: 100%;
        height: auto;
      }

      &:nth-child(n + 4) {
        grid-column: span 3;
        @media screen and (min-width: @bp-mobile-small) and (max-width: @bp-mobile-big) {
          width: 100%;
          height: 154px !important;
        }
        @media screen and (max-width: @bp-mobile-small) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &_8 {
    @media screen and (min-width: @bp-mobile-small) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: @spacing-s;
    }

    .gallery__item {
      grid-column: span 3;
      height: 246px;
      padding: 0;
      @media screen and (min-width: @bp-mobile-small) and (max-width: @bp-mobile-big) {
        height: 154px !important;
      }
      @media screen and (max-width: @bp-mobile-small) {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
